@font-face {
  font-family: "c&c";
  src: local("c&c"), url(./c&c.ttf) format("truetype");
}

@font-face {
  font-family: "gameplay";
  src: local("gameplay"), url(./gameplay.ttf) format("truetype");
}

@font-face {
  font-family: "popwarner";
  src: local("popwarner"), url(./popwarner.ttf) format("truetype");
}

@font-face {
  font-family: "pixel";
  src: local("pixel"), url(./newPixel.ttf) format("truetype");
}

@font-face {
  font-family: "mops";
  src: local("mops"), url(./mops.ttf) format("truetype");
}


@font-face {
  font-family: "concertOne";
  src: local("concertOne"), url(./concertOne.ttf) format("truetype");
}
/* WARNING For some fucking reason i cant get these to load to the relative path of the project,
so they must go at critkitchen.com/dist/fonts regardless of what project this is*/